import React from "react";

const TipTapLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 120 28"
  >
    <path
      fill="#000"
      d="M44.444 22.826h3.933V8.727h5.665V5.623H38.753v3.104h5.691v14.1ZM54.871 22.826h3.83V9.891h-3.83v12.935Zm-.18-14.642h4.164V5.623H54.69v2.56ZM69.85 23.24c4.216 0 5.95-3.208 5.95-6.881 0-3.726-1.734-6.882-5.95-6.882-2.225 0-3.726.854-4.631 2.329V9.89h-3.83V28h3.83v-7.114c.905 1.474 2.406 2.354 4.63 2.354Zm-4.631-6.881c0-2.639 1.034-3.88 3.44-3.88 2.432 0 3.312 1.19 3.312 3.88 0 2.664-.88 3.88-3.312 3.88-2.406 0-3.44-1.242-3.44-3.88ZM83.633 22.826h2.432v-3.053h-3.363V12.79h3.725V9.89h-3.725V6.813h-1.94l-1.579 3.052-2.664 1.087v1.837h2.354v5.38c0 3.08 1.086 4.657 4.76 4.657ZM93.545 23.24c2.225 0 3.674-.88 4.527-2.354v1.94h3.829V9.891h-3.829v1.915c-.853-1.475-2.302-2.329-4.527-2.329-4.269 0-5.82 3.13-5.82 6.882 0 3.673 1.552 6.881 5.82 6.881Zm-2.018-6.881c0-2.768.88-3.88 3.208-3.88 2.25 0 3.337 1.06 3.337 3.88 0 2.794-1.086 3.88-3.337 3.88-2.328 0-3.208-1.138-3.208-3.88ZM113.1 23.24c4.217 0 5.95-3.208 5.95-6.881 0-3.726-1.733-6.882-5.95-6.882-2.225 0-3.725.854-4.631 2.329V9.89h-3.828V28h3.828v-7.114c.906 1.474 2.406 2.354 4.631 2.354Zm-4.631-6.881c0-2.639 1.035-3.88 3.441-3.88 2.432 0 3.311 1.19 3.311 3.88 0 2.664-.879 3.88-3.311 3.88-2.406 0-3.441-1.242-3.441-3.88ZM22.305 3.043A13.638 13.638 0 0 0 13.695 0c-3.26 0-6.256 1.14-8.608 3.043h17.218ZM.78 9.13a13.647 13.647 0 0 1 1.526-3.043h22.779c.628.939 1.144 1.96 1.527 3.043H.779ZM.084 15.217a13.846 13.846 0 0 1 0-3.043h27.224a13.83 13.83 0 0 1 0 3.043H.084ZM2.306 21.304A13.647 13.647 0 0 1 .78 18.261h25.833a13.648 13.648 0 0 1-1.527 3.043H2.306ZM22.305 24.348a13.638 13.638 0 0 1-8.61 3.043c-3.26 0-6.256-1.14-8.608-3.043h17.218Z"
    />
  </svg>
);

export default TipTapLogo;
