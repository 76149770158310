import React from "react";
import { mergeAttributes } from "@tiptap/core";
import { Node as ProseMirrorNode } from "prosemirror-model";
import Extension, { RenderHTMLPropTypes } from "../factories/Extension";
// @ts-ignore TS7016
import { getMentionCandidates, MentionHit } from "../../MentionTextarea";

const char = "@";
const dataKey = "data-type";
export const name = "mention";
const renderText = ({ node }: { node: ProseMirrorNode }) => `${char}${node.attrs.name}`;

export type MentionType = {
  name: string;
  href: string;
  avatar: string;
};

const Mention = ({ containerRef }: { containerRef: React.RefObject<HTMLDivElement> }) =>
  Extension<MentionType>({
    name,
    char,
    containerRef,
    renderText,

    async onQuery(query: string): Promise<MentionType[]> {
      return getMentionCandidates(query);
    },

    addAttributes: () => ({
      name: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element: HTMLElement) => element.getAttribute("data-name"),
        renderHTML: (item: MentionType) => ({
          "data-name": item.name,
        }),
      },

      href: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element: HTMLElement) => element.getAttribute("href"),
        renderHTML: (item: MentionType) => ({
          href: item.href,
        }),
      },
    }),

    parseHTML: () => [{ tag: `[${dataKey}="${name}"]` }],

    renderHTML: ({ node, HTMLAttributes }: RenderHTMLPropTypes) => [
      "a",
      mergeAttributes({ [dataKey]: name }, HTMLAttributes),
      renderText({ node }),
    ],

    Hit: ({ item }) => MentionHit(item as MentionType),
  });

export default Mention;
