import React, { useState, useRef, useEffect, useCallback } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { Editor, Extension, ChainedCommands } from "@tiptap/core";
import { Mark } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";
// extensions
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { Level } from "@tiptap/extension-heading";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faItalic,
  faStrikethrough,
  faLink,
  faListOl,
  faListUl,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt, faImage } from "@fortawesome/free-regular-svg-icons";
// custom extensions
import classNames from "classnames";
import { debounce } from "lodash";
import Image from "./extensions/Image";
import Mention from "./extensions/Mention";
import Emoji from "./extensions/Emoji";
import Commands from "./extensions/Commands";
// markdown
import { prosemirrorToMarkdown, markdownToHtml } from "./markdown/markdown";
import { EditorPropTypes } from "../MarkdownEditor";
// @ts-ignore TS7016
import { hasMention, MentionWarningText } from "../MentionTextarea";
import giphyAttribution from "./assets/giphy-attribution.png";

type Flash = {
  text: string;
  subtext?: string;
};

export type ToggleHeadingCommand = ChainedCommands & {
  toggleHeading: (attributes: { level: Level }) => boolean;
};

// Keep in sync with shared/gems/shared_forum/app/controllers/shared_forum/media_controller.rb
const VALID_IMAGE_TYPES = ["image/png", "image/jpeg", "image/webp", "image/gif", "image/heic"];

export default ({
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  imageButton,
  mentionsEnabled,
  showHeader,
  debounceMs,
  largeHeadingSize = 1,
  isControlled = false,
}: EditorPropTypes) => {
  const [showGifModal, setShowGifModal] = useState(false);
  const [showLinkEditor, setShowLinkEditor] = useState(false);
  const [flash, setFlash] = useState<null | Flash>(null);
  const [markdown, setMarkdown] = useState<string>(value);
  const containerRef = useRef<HTMLDivElement>(null);

  const extensions = [
    StarterKit as Extension<any>,
    Highlight,
    Typography,
    Image,
    Link.configure({
      openOnClick: false,
    }),
    Emoji({
      containerRef,
    }).Node,
    Commands({
      setShowGifModal,
      setShowLinkEditor,
      containerRef,
      largeHeadingSize,
    }).Node,
    Placeholder.configure({
      emptyNodeClass: "show-placeholder",
      placeholder,
    }),
  ];

  if (mentionsEnabled) {
    extensions.push(
      Mention({
        containerRef,
      }).Node
    );
  }

  const getMarkdown = ({ editor }: { editor: Editor }) =>
    prosemirrorToMarkdown(editor.schema, editor.getJSON());

  const handleUpdate = ({ editor }: { editor: Editor }) => {
    const md = getMarkdown({ editor });
    if (onChange) onChange(md);
    setMarkdown(md);
  };

  const debouncedHandleUpdate = useCallback(debounce(handleUpdate, debounceMs || 0), [onChange]);

  const editor = useEditor({
    extensions,
    // hydrate prosemirror JSON state with HTML converted from stored MD
    content: markdownToHtml(value),
    // update state with MD converted from prosemirror JSON
    onUpdate: debouncedHandleUpdate,
    onBlur: () => {
      if (onBlur) onBlur();
    },
    onFocus: () => {
      if (onFocus) onFocus();
    },
    onDestroy: () => {
      if (editor && editor.contentComponent) {
        // @ts-ignore
        editor.contentComponent.initialized = false;
      }
    },
  });

  // handle updates to onChange handler
  useEffect(() => {
    if (editor) {
      editor.off("update");
      editor.on("update", debouncedHandleUpdate);
    }
  }, [editor, onChange]);

  // Allow parent component to reset editor state
  // NB: we would like to add value to the dependencies array of the
  // useEditor hook, but the tiptap core does not yet support
  // dependency declaration on the hook; see discussion at
  // https://github.com/ueberdosis/tiptap/issues/2403
  useEffect(() => {
    if (editor && value === "") editor.commands.setContent("");
    if (editor && value !== "" && isControlled) {
      const { from, to } = editor.state.selection;
      editor.commands.setContent(markdownToHtml(value), false, { preserveWhitespace: true });
      editor.commands.setTextSelection({ from, to });
    }
  }, [editor, value]);

  const handleImageFile = useCallback(
    async (file: File) => {
      // ensure editor is ready
      if (!editor)
        return setFlash({
          text: "Please try again later",
        });
      const validImage = VALID_IMAGE_TYPES.includes(file.type);
      if (!validImage) {
        return setFlash({
          text: "Oops! Only some image files are allowed. Please upload a jpeg, png, or gif.",
        });
      }
      // ensure file is not huge
      const mb = Math.round((file.size / 1024 / 1024) * 10) / 10;
      const maxMb = 10;
      if (mb > maxMb) {
        return setFlash({
          text: `Oops! Please limit uploads to ${maxMb}MB.`,
          subtext: `(Your file was ${mb}MB)`,
        });
      }
      // uploads happen within the image node itself
      editor
        .chain()
        .focus()
        .setImage({
          // @ts-ignore TS2345 - upstream typecheck on setImage arg needs attrs from node view
          file,
        })
        .run();
    },
    [editor]
  );

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e?.dataTransfer?.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          const file = e.dataTransfer.items[i].getAsFile();
          if (file) {
            handleImageFile(file);
          }
        }
      }
    }
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "k" && editor?.view.hasFocus()) {
        e.preventDefault();
        e.stopPropagation();
        setShowLinkEditor(!showLinkEditor);
      }
    };
    document.body.addEventListener("keydown", onKeyDown);
    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
    };
  }, [showLinkEditor, editor]);

  return (
    <div className="shared-editor-container" ref={containerRef}>
      {showHeader && (
        <MenuBar
          editor={editor}
          handleImageFile={handleImageFile}
          imageButton={imageButton}
          showLinkEditor={showLinkEditor}
          setShowLinkEditor={setShowLinkEditor}
          largeHeadingSize={largeHeadingSize}
        />
      )}
      <EditorContent editor={editor} className="shared-editor-textarea" onDrop={onDrop} />
      {showGifModal && <GifModal editor={editor} setShowGifModal={setShowGifModal} />}
      {flash && <FlashMessage message={flash} setFlash={setFlash} />}
      {mentionsEnabled && hasMention(markdown) && <MentionWarningText />}
    </div>
  );
};

/**
 * Flash Message
 * */

type FlashProps = {
  message: Flash;
  setFlash: (arg: null | Flash) => void;
};

const FlashMessage = ({ message, setFlash }: FlashProps) => (
  <div className="shared-editor-flash" onClick={() => setFlash(null)}>
    <div className="x-dismiss">×</div>
    <div className="shared-editor-flash-message">{message.text}</div>
    <div className="shared-editor-flash-subtext">{message.subtext}</div>
  </div>
);

/**
 * Menu
 * */

const MenuBar = ({
  editor,
  handleImageFile,
  imageButton,
  showLinkEditor,
  setShowLinkEditor,
  largeHeadingSize,
}: {
  editor: Editor | null;
  handleImageFile: (file: File) => void;
  imageButton?: boolean;
  showLinkEditor: boolean;
  setShowLinkEditor: (arg: boolean) => void;
  largeHeadingSize: Level;
}) => {
  if (!editor) return null;
  const fileUploadRef = useRef<HTMLInputElement>(null);
  return (
    <div className="shared-editor-menu">
      <select
        tabIndex={-1}
        className="shared-editor-menu-select"
        value={
          // eslint-disable-next-line no-nested-ternary
          editor.isActive("heading", { level: largeHeadingSize })
            ? "heading-large"
            : editor.isActive("heading", { level: 3 })
            ? "heading-medium"
            : "text"
        }
        onChange={(e) => {
          const { value } = e.target;
          if (value === "text") editor.chain().focus().setParagraph().run();
          if (value === "heading-large")
            (editor.chain().focus() as ToggleHeadingCommand)
              .toggleHeading({ level: largeHeadingSize })
              .run();
          if (value === "heading-medium")
            (editor.chain().focus() as ToggleHeadingCommand).toggleHeading({ level: 3 }).run();
        }}
      >
        <option value="text">Text</option>
        <option value="heading-large">Heading 1</option>
        <option value="heading-medium">Heading 2</option>
      </select>

      <MenuButton
        title="Bold"
        onClick={(e) => editor.chain().focus().toggleBold().run()}
        active={editor.isActive("bold")}
      >
        <FontAwesomeIcon icon={faBold} />
      </MenuButton>

      <MenuButton
        title="Italic"
        // @ts-ignore TS2339
        onClick={(e) => editor.chain().focus().toggleItalic().run()}
        active={editor.isActive("italic")}
      >
        <FontAwesomeIcon icon={faItalic} />
      </MenuButton>

      <MenuButton
        title="Strikethrough"
        // @ts-ignore TS2339
        onClick={(e) => editor.chain().focus().toggleStrike().run()}
        active={editor.isActive("strike")}
      >
        <FontAwesomeIcon icon={faStrikethrough} />
      </MenuButton>

      <MenuButton
        title="Link"
        onPointerDown={(e) => {
          const { $cursor } = getSelection(editor);
          e.stopPropagation();
          e.preventDefault();
          // unset the link if it's active, else allow user to create a link
          if ($cursor && editor.isActive("link")) {
            editor.commands.unsetLink();
          } else {
            setShowLinkEditor(!showLinkEditor);
          }
        }}
        active={editor.isActive("link")}
      >
        <FontAwesomeIcon icon={faLink} />
        <LinkEditor
          editor={editor}
          showLinkEditor={showLinkEditor}
          setShowLinkEditor={setShowLinkEditor}
        />
      </MenuButton>

      <MenuButton
        title="Ordered List"
        onClick={(e) => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
      >
        <FontAwesomeIcon icon={faListOl} />
      </MenuButton>

      <MenuButton
        title="Unordered List"
        onClick={(e) => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
      >
        <FontAwesomeIcon icon={faListUl} />
      </MenuButton>

      <MenuButton
        title="Blockquote"
        onClick={(e) => editor.chain().focus().toggleBlockquote().run()}
        active={editor.isActive("blockquote")}
      >
        <FontAwesomeIcon icon={faCommentAlt} />
      </MenuButton>

      <MenuButton
        title="Code Block"
        onClick={(e) => editor.chain().focus().toggleCodeBlock().run()}
        active={editor.isActive("codeBlock")}
      >
        <FontAwesomeIcon icon={faTerminal} />
      </MenuButton>

      {imageButton !== false && (
        <MenuButton
          title="Image"
          onClick={(e) => {
            fileUploadRef?.current?.click();
          }}
          active={editor.isActive("image")}
          className="image-icon"
        >
          <FontAwesomeIcon icon={faImage} />
        </MenuButton>
      )}

      <input
        onChange={(e) => {
          if (e?.target?.files && e.target.files[0]) {
            handleImageFile(e.target.files[0]);
            if (fileUploadRef.current) fileUploadRef.current.value = "";
          }
        }}
        type="file"
        ref={fileUploadRef}
        className="file-upload-input"
        accept="image/*"
        tabIndex={-1}
      />
    </div>
  );
};

type MenuButtonProps = {
  title: string;
  onClick?: (e: React.MouseEvent) => void;
  onPointerDown?: (e: React.PointerEvent) => void;
  active: boolean;
  className?: string;
  children?: React.ReactNode;
};

const MenuButton = ({
  title,
  onClick,
  onPointerDown,
  active,
  className,
  children,
}: MenuButtonProps) => (
  <button
    type="button"
    title={title}
    tabIndex={-1}
    onClick={onClick}
    onPointerDown={onPointerDown}
    className={classNames("editor-menu-button align-center justify-center", {
      active,
      className,
    })}
  >
    {children}
  </button>
);

/**
 * Link Editor
 * */

const LinkEditor = ({
  editor,
  showLinkEditor,
  setShowLinkEditor,
}: {
  editor: Editor;
  showLinkEditor: boolean;
  setShowLinkEditor: (bool: boolean) => void;
}) => {
  const defaultLinkHref = "";
  const [linkText, setLinkText] = useState("");
  const [linkHref, setLinkHref] = useState(defaultLinkHref);
  const textRef = useRef<HTMLInputElement>(null);
  const hrefRef = useRef<HTMLInputElement>(null);

  // transform the dom using the link text and href sent by user
  const editLink = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
    // get the active selection
    const { state, $cursor, from, to, start, end, node } = getSelection(editor);
    // get text to insert
    const text = linkText || linkHref || "";
    // determine if we need to insert leading / trailing whitespace
    const wrapInWhitespace = false;
    const isClick = from === to && node && "marks" in (node as any);
    const first = isClick && start ? start : from;
    const last = isClick && end ? end : to;
    const prevChar = state.doc.textBetween(first - 1, first, "") || "";
    const nextChar = state.doc.textBetween(last, last + 1, "") || "";
    let head = prevChar === " " || text[0] === " " ? "" : " ";
    const tail = nextChar === " " || text[text.length - 1] === " " ? "" : " ";
    // remove head if cursor is at start of block
    if ($cursor && $cursor.parentOffset === 0) {
      head = "";
    }
    // add a scheme/protocol if necessary
    const hasTld = linkHref.includes(".");
    const hasProtocol =
      linkHref.includes("://") ||
      linkHref.trim().startsWith("#") ||
      linkHref.trim().startsWith("/") ||
      !hasTld;
    const href = hasProtocol ? linkHref : `https://${linkHref}`;
    // add content
    if (linkHref) {
      editor
        .chain()
        .setTextSelection({ from: first, to: last })
        .insertContent(wrapInWhitespace ? head : "")
        .setLink({ href })
        .insertContent(text)
        .unsetMark("link")
        .insertContent(wrapInWhitespace ? tail : "")
        .focus()
        .run();
    } else {
      editor
        .chain()
        .setTextSelection({ from: first, to: last })
        .unsetLink()
        .insertContent(`${head}${text}${tail}`)
        .focus()
        .run();
    }
    setShowLinkEditor(false);
  };

  // update the link editor as selection changes
  useEffect(() => {
    const onSelectionUpdate = () => {
      const { text, href } = getSelection(editor);
      setLinkText(text || "");
      setLinkHref(href || defaultLinkHref);
    };
    editor.on("selectionUpdate", onSelectionUpdate);
    return () => {
      editor.off("selectionUpdate", onSelectionUpdate);
    };
  }, [editor]);

  useEffect(() => {
    // set focus according to the input states
    if (showLinkEditor) {
      if (linkText) {
        hrefRef?.current?.focus();
      } else {
        textRef?.current?.focus();
      }
    }
  }, [showLinkEditor]);

  // allow outside clicks to close the link editor
  useEffect(() => {
    const onPointerDown = () => {
      setShowLinkEditor(false);
    };
    window.addEventListener("pointerdown", onPointerDown);
    return () => {
      window.removeEventListener("pointerdown", onPointerDown);
    };
  }, []);

  // if a user clicks on a link, open the link editor
  useEffect(() => {
    const onPointerUp = (e: MouseEvent) => {
      if (!editor.view.hasFocus()) {
        return;
      }

      // identify the click target
      const target = e.target as HTMLElement;

      const targetHref = target.getAttribute("href");

      // handle case of click on href
      const { href, from, to } = getSelection(editor);
      if (targetHref && href && from === to) {
        setShowLinkEditor(true);
      }
    };
    window.addEventListener("pointerup", onPointerUp);
    return () => {
      window.removeEventListener("pointerup", onPointerUp);
    };
  }, [editor]);

  return showLinkEditor ? (
    <div
      className="link-input"
      onPointerDown={(e: React.PointerEvent) => e.stopPropagation()}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") editLink(e);
      }}
    >
      <input
        ref={textRef}
        type="text"
        placeholder="Text"
        value={linkText}
        onChange={(e) => setLinkText(e.target.value)}
      />
      <input
        ref={hrefRef}
        type="url"
        placeholder="https://"
        value={linkHref}
        onChange={(e) => setLinkHref(e.target.value)}
      />
      <div className="row flex-end">
        <span
          className="cancel-button"
          onPointerUp={(e) => {
            setShowLinkEditor(false);
            e.stopPropagation();
          }}
        >
          Cancel
        </span>
        <span onPointerDown={editLink}>Submit</span>
      </div>
    </div>
  ) : null;
};

/**
 * Gif Modal
 * */

type GiphyApiResult = {
  images: {
    downsized: {
      width: string;
      height: string;
      url: string;
    };
  };
};

const sample = (arr: any[]) => arr[Math.floor(arr.length * Math.random())];

const GifModal = ({
  editor,
  setShowGifModal,
}: {
  editor: Editor | null;
  setShowGifModal: (arg: boolean) => void;
}) => {
  const values: string[] = [
    "awesome",
    "fantastic",
    "super",
    "terrific",
    "outstanding",
    "thumbs up",
    "high five",
  ];
  const [value, setValue] = useState<string>(sample(values));
  const [results, setResults] = useState<GiphyApiResult[]>([]);
  const [index, setIndex] = useState(0);
  const size = "downsized";

  const query = async () => {
    if (!value) return;
    const url = `https://api.giphy.com/v1/gifs/search?q=${escape(
      value
    )}&api_key=4KLmX8z32ajpfyP3VFabTMGXLjD5QH4y`;
    const response = await fetch(url);
    const json = await response.json();
    // remove portrait images because they're too large
    const filtered = json.data.filter((i: GiphyApiResult, idx: number) => {
      const im = i.images[size];
      const { width, height } = im;
      return parseFloat(width) / parseFloat(height) >= 1.0;
    });
    setIndex(0);
    setResults(filtered);
  };

  const submit = () => {
    if (!editor) return;
    const im = results[index].images[size].url;
    editor.chain().focus().setImage({ src: im }).run();
    setShowGifModal(false);
  };

  useEffect(() => {
    query();
  }, []);

  return (
    <div id="shared-editor-gif-modal" onPointerDown={(e) => setShowGifModal(false)}>
      <div id="shared-editor-gif-modal-body" onPointerDown={(e) => e.stopPropagation()}>
        <input
          autoFocus
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            query();
          }}
        />
        <div id="shared-editor-gif-display">
          {results.length > 0 ? (
            <>
              <img src={results[index].images[size].url} alt="giphy search result" />
              {/* preload the next image */}
              <img
                className="hidden"
                src={results[(index + 1) % results.length].images[size].url}
                alt="giphy search result"
              />
            </>
          ) : null}
        </div>
        <div className="row space-between align-center buttons">
          <div className="row align-center">
            <button className="little-button" onPointerDown={(e) => submit()}>
              Send
            </button>
            <button
              className="little-button"
              onPointerDown={(e) => setIndex((index + 1) % results.length)}
            >
              Shuffle
            </button>
            <button className="little-button" onPointerDown={(e) => setShowGifModal(false)}>
              Cancel
            </button>
          </div>
          <a href="https://developers.giphy.com/docs/api/endpoint/#search">
            <img className="giphy-attribution" src={giphyAttribution} alt="giphy attribution" />
          </a>
        </div>
      </div>
    </div>
  );
};

/**
 * Prosemirror helpers
 * */

export const getSelection = (editor: Editor) => {
  const { state } = editor.view;
  const { selection } = state;
  const { from, to, $cursor } = selection as TextSelection;
  // start and end represent the offsets of the selected node
  let start;
  let end;
  let text;
  let href;
  // find the node and extant href
  const node = state.doc.nodeAt(from) || false;
  if ($cursor) {
    // if this is a click, select the text from the node if possible
    text = node ? node.textContent : "";
    const marks = node ? node.marks : [];
    [href] = marks.filter((m: Mark) => "href" in (m.attrs || {}));
    // case where the selection was triggered by click of a link
    if (href) {
      href = href.attrs.href;
      // get the start and end positions of the node
      const { parent, parentOffset } = $cursor;
      start = parent.childBefore(parentOffset).offset + $cursor.start();
      const size = node ? node.nodeSize : 0;
      end = start + size;
      // case where the selection was triggered by typing cursor
    } else {
      text = state.doc.textBetween(from, to, "") || "";
    }
    // case where selection was triggered by a drag selection
  } else {
    text = state.doc.textBetween(from, to, "") || "";
  }

  return {
    state,
    selection,
    $cursor,
    from,
    to,
    start,
    end,
    node,
    href,
    text,
  };
};
