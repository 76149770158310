import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import ResizeObserver from "resize-observer-polyfill";
import { Level } from "@tiptap/extension-heading";
// @ts-ignore TS7016
import { MarkdownComponent } from "../renderMarkdown";
// @ts-ignore TS7016
import MentionTextarea from "./MentionTextarea";
import TipTapEditor from "./tiptap/TipTapEditor";
import TipTapLogo from "./tiptap/TipTapLogo";
import "./editor.scss";

export interface EditorPropTypes {
  value: string;
  className?: string;
  preview?: React.ComponentType<{ content: string; className: string }>;
  placeholder?: string;
  imageButton?: boolean;
  mentionsEnabled?: boolean;
  useRawTextDefault?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  initialHeight?: number;
  newHeightValue?: number;
  largeHeadingSize?: Level;
  onSetTab?: (tab: TabState) => void;
  onChange?: (s: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  debounceMs?: number;
  isControlled?: boolean;
}

export type TabState = "raw" | "edit" | "preview";

export default function MarkdownEditor({
  className,
  preview,
  placeholder,
  value,
  imageButton,
  mentionsEnabled,
  useRawTextDefault,
  showHeader = true,
  showFooter = true,
  debounceMs,
  initialHeight = 300,
  newHeightValue,
  largeHeadingSize = 1,
  onSetTab,
  onChange,
  onFocus,
  onBlur,
  isControlled,
}: EditorPropTypes) {
  // tab options: edit (milkdown gui) | raw (plaintext markdown) | preview (render preview)
  const [tab, setTabState] = useState<TabState>(
    useRawTextDefault || /android/i.test(navigator.userAgent) ? "raw" : "edit"
  );
  const [height, setHeight] = useState<number>(initialHeight);
  const [nextHeight, setNextHeight] = useState<number>(initialHeight);
  const editorClassName = "shared-markdown-editor";

  // callback triggered when editor changes size
  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        const { target } = entry;
        if (target.classList.contains(editorClassName)) {
          const box = target.getBoundingClientRect();
          setNextHeight(box.height);
        }
      });
    })
  );

  // binds the resize listener to the editor
  const resizedContainerRef = React.useCallback(
    (container: HTMLDivElement) => {
      if (container !== null) {
        resizeObserver.current.observe(container);
      } else if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    },
    [resizeObserver.current]
  );

  const setTab = (nextTab: TabState) => {
    setHeight(nextHeight);
    setTabState(nextTab);
    if (onSetTab) onSetTab(nextTab);
  };

  useEffect(() => {
    // hoist state to parent scope if necessary
    setTab(tab);
  }, []);

  useEffect(() => {
    if (newHeightValue && !isNaN(newHeightValue)) {
      setHeight(newHeightValue);
    }
  }, [newHeightValue]);

  let main;
  if (tab === "preview") {
    main = <Preview content={value} preview={preview || MarkdownComponent} />;
  } else if (tab === "edit") {
    main = (
      <TipTapEditor
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        imageButton={imageButton || false}
        mentionsEnabled={mentionsEnabled || false}
        showHeader={showHeader}
        debounceMs={debounceMs}
        largeHeadingSize={largeHeadingSize}
        isControlled={isControlled}
      />
    );
  } else if (tab === "raw") {
    main = (
      <MentionTextarea
        mentionsEnabled={mentionsEnabled}
        placeholder={placeholder}
        className="raw-textarea"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        debounceMs={debounceMs}
      />
    );
  }

  return (
    <div
      className={classNames(
        `${editorClassName} post-body post-body-preview form-control prose w-full max-w-none bg-white bg-white text-base font-normal`,
        className
      )}
      ref={resizedContainerRef}
      style={{ minHeight: `${height}px` }}
    >
      {main}
      {showFooter && (
        <div className="shared-markdown-editor-footer">
          <div>
            <span
              className={tab === "edit" ? "active little-button" : "little-button"}
              onClick={() => setTab("edit")}
            >
              Edit
            </span>
            <span
              className={tab === "preview" ? "active little-button" : "little-button"}
              onClick={() => setTab("preview")}
            >
              Preview
            </span>
          </div>

          <a
            href={
              window.location.href.includes("bookface") ? "/company/29079" : "https://tiptap.dev/"
            }
            target="_blank"
            className="tiptap-logo"
          >
            <span>Editor by</span>
            <TipTapLogo />
          </a>

          <div>
            {tab === "raw" && (
              <a target="_blank" href="https://commonmark.org/help/">
                Markdown Reference
              </a>
            )}
            <span
              className={tab === "raw" ? "active little-button" : "little-button"}
              onClick={() => setTab("raw")}
            >
              Raw
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

const Preview = ({ preview, content }: { preview?: React.ComponentType<any>; content: string }) => {
  const PreviewComponent = preview || MarkdownComponent;
  return <PreviewComponent className="markdown-preview" content={content} />;
};
