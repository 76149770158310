// tiptap extensions
import Bold from "@tiptap/extension-bold";
import Strike from "@tiptap/extension-strike";
import Italic from "@tiptap/extension-italic";
import Code from "@tiptap/extension-code";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import OrderedList from "@tiptap/extension-ordered-list";
import HardBreak from "@tiptap/extension-hard-break";
import CodeBlock from "@tiptap/extension-code-block";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Blockquote from "@tiptap/extension-blockquote";
// prosemirror & tiptap types
import { Mark, Node, Schema } from "prosemirror-model";
import {
  MarkdownSerializerState,
  MarkdownSerializer,
  defaultMarkdownSerializer,
} from "prosemirror-markdown";
// markdown to prosemirror conversion
import { marked } from "marked";
// custom extensions
import { name as MentionName } from "../extensions/Mention";
import { name as EmojiName } from "../extensions/Emoji";
import Image from "../extensions/Image";

/**
 * Mark helpers
 * */

const strikeMark = {
  open: "~~",
  close: "~~",
  mixable: true,
  expelEnclosingWhitespace: true,
};

const italicMark = {
  open: "_",
  close: "_",
  mixable: true,
  expelEnclosingWhitespace: true,
};

const linkMark = {
  open(state: MarkdownSerializerState, mark: Mark, parent: Node, index: number) {
    return isPlainURL(mark, parent, index, 1) ? "<" : "[";
  },
  close(state: MarkdownSerializerState, mark: Mark, parent: Node, index: number) {
    const href = mark.attrs.canonicalSrc || mark.attrs.href;
    const dest = unescapeUnderscores(state.esc(href));
    return isPlainURL(mark, parent, index, -1)
      ? ">"
      : // @ts-ignore (quote is an internal method)
        `](${dest}${mark.attrs.title ? state.quote(mark.attrs.title) : ""})`;
  },
  expelEnclosingWhitespace: true,
};

const isPlainURL = (link: Mark, parent: Node, index: number, side: number) => {
  if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) return false;
  const content = parent.child(index + (side < 0 ? -1 : 0));
  if (
    !content.isText ||
    content.text !== link.attrs.href ||
    content.marks[content.marks.length - 1] !== link
  )
    return false;
  if (index === (side < 0 ? 1 : parent.childCount - 1)) return true;
  const next = parent.child(index + (side < 0 ? -2 : 1));
  return !link.isInSet(next.marks);
};

/**
 * Node helpers
 * */

const codeblockLowlightNode = (state: MarkdownSerializerState, node: Node) => {
  state.write(`\`\`\`${node.attrs.language || ""}\n`);
  state.text(node.textContent, false);
  state.ensureNewLine();
  state.write("```");
  state.closeBlock(node);
};

const orderedListNode = (state: MarkdownSerializerState, node: Node) => {
  const { parens } = node.attrs;
  const start = node.attrs.start || 1;
  const maxW = String(start + node.childCount - 1).length;
  const space = state.repeat(" ", maxW + 2);
  const delimiter = parens ? ")" : ".";
  state.renderList(node, space, (i) => {
    const nStr = String(start + i);
    return `${state.repeat(" ", maxW - nStr.length) + nStr}${delimiter} `;
  });
};

const blockquoteNode = (state: MarkdownSerializerState, node: Node) => {
  if (node.attrs.multiline) {
    state.write(">>>");
    state.ensureNewLine();
    state.renderContent(node);
    state.ensureNewLine();
    state.write(">>>");
    state.closeBlock(node);
  } else {
    state.wrapBlock("> ", null, node, () => state.renderContent(node));
  }
};

const imageNode = (state: MarkdownSerializerState, node: Node) => {
  if (!node.attrs.src) return;
  let s = "";
  s += `![${state.esc((node.attrs.alt || "").toString() || "")}]`;
  s += `(${node.attrs.src})`;
  s += "\n\n";
  state.write(s);
};

const mentionNode = (state: MarkdownSerializerState, node: Node) => {
  const { name, href } = node.attrs;
  if (!name || !href) return;
  state.write(`[@${name}](${href})`);
};

const emojiNode = (state: MarkdownSerializerState, node: Node) => {
  const { native } = node.attrs;
  state.write(native);
};

/**
 * Conversions
 * */

// supported ProseMirror marks
const marks = {
  ...defaultMarkdownSerializer.marks,
  [Bold.name]: defaultMarkdownSerializer.marks.strong,
  [Code.name]: defaultMarkdownSerializer.marks.code,
  [Strike.name]: strikeMark,
  [Italic.name]: italicMark,
  [Link.name]: linkMark,
};

// supported ProseMirror nodes
const nodes = {
  ...defaultMarkdownSerializer.nodes,
  [Paragraph.name]: defaultMarkdownSerializer.nodes.paragraph,
  [BulletList.name]: defaultMarkdownSerializer.nodes.bullet_list,
  [ListItem.name]: defaultMarkdownSerializer.nodes.list_item,
  [HorizontalRule.name]: defaultMarkdownSerializer.nodes.horizontal_rule,
  [HardBreak.name]: defaultMarkdownSerializer.nodes.hard_break,
  [Image.name]: imageNode,
  [OrderedList.name]: orderedListNode,
  [CodeBlock.name]: codeblockLowlightNode,
  [CodeBlockLowlight.name]: codeblockLowlightNode,
  [Blockquote.name]: blockquoteNode,
  [MentionName]: mentionNode,
  [EmojiName]: emojiNode,
};

// convert ProseMirror JSON -> Markdown
export const prosemirrorToMarkdown = (schema: Schema, proseMirrorJson: any) => {
  const proseMirrorDocument = schema.nodeFromJSON(proseMirrorJson);
  const serializer = new MarkdownSerializer(nodes, marks);
  const serialized = serializer.serialize(proseMirrorDocument, {
    tightLists: true,
  });
  return unescapeUnderscores(serialized);
};

// convert Markdown -> HTML
export const markdownToHtml = (md: string): string => {
  const html = marked.parse(md || "");
  return escapeImagesFromParagraphTags(html);
};

// _ gets escaped below; the replaceAll reverses the escape
// related discussion: https://github.com/ProseMirror/prosemirror-markdown/pull/66
const unescapeUnderscores = (s: string) => s.replaceAll("\\_", "_");

// images are configured as block but are rendered inside p tags, which are block level.
// prosemirror's parser throws on nested blocks, so images must be pulled from p tags.
// discussion: https://github.com/ueberdosis/tiptap/issues/1206
const escapeImagesFromParagraphTags = (html: string): string => {
  try {
    const dom = new DOMParser().parseFromString(html.trim(), "text/html");
    const images = dom.querySelectorAll("img");
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      const p = img.parentNode;
      if (p?.nodeName?.toLowerCase() === "p") {
        p?.parentNode?.replaceChild(img, p);
      }
    }
    return dom.body.innerHTML;
  } catch (err) {
    // we shouldn't expect any parsing errors, but if one were to occur for an
    // unforseen reason the user could get trapped in a situation in which the
    // post saved in local storage will not deserialize. If the block below
    // triggers, we will need to investigate the post and determine why the escaping
    // above causes an issue
    // eslint-disable-next-line no-console
    console.warn(err);
    return html;
  }
};
