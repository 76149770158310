// @ts-ignore TS7016
import authorizedFetch from "../../../authorizedFetch";

type ResponseJson = {
  url: string;
  key: string;
  bucket: string;
  media_upload_id: number;
  fields: Array<[string, string]>;
};

type Config = {
  upload_type: string;
  upload_metadata?: { [key: string]: string };
};

// POST to rails to get S3 url to which we'll POST media file
const getPresignedPutUrl = async (file: File, config: Config): Promise<ResponseJson> =>
  authorizedFetch("/media", {
    method: "POST",
    body: JSON.stringify({
      ...config,
      content_type: file.type,
    }),
  }).then((result: Response) => result.json());

// POST media file to the presigned S3 url
const uploadMedia = async (file: File, url: string, fields: Array<[string, string]>) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) resolve(xhr.response);
      else reject(new Error(`POST request failed with code ${xhr.status}: ${xhr.statusText}`));
    };
    xhr.open("POST", url);

    const formData = new FormData();
    fields.forEach(([key, field_val]) => {
      // Since we upload files directly to Amazon, we need a "server side" way
      // to enforce the content type is safe (specifically not an SVG). In this
      // flow, the browser tells our server what content type they're going to
      // upload and we encode that in a policy which Amazon can check. In the
      // common case, those values will be a safe image and agree. But if
      // someone is trying to mess with us, one of 2 things will happen
      // depending on how they attack us:
      // 1. they'll tell us one "safe" value and amazon an "unsafe" value and
      // then based on the policy we set with amazon, amazon will reject the
      // file
      // 2. they'll tell us and amazon both the "safe" value but upload bytes
      // for a different image format. In that case, Amazon will accept the file
      // but it will only serve it with the "safe" Content-Type. So browsers and
      // operating systems will think it's an invalid png.
      const val = key === "Content-Type" ? file.type : field_val;
      formData.append(key, val);
    });
    // Actual file has to be appended last.
    formData.append("file", file);
    xhr.send(formData);
  });

// upload file and return url to object in S3
export const uploadToS3 = async (file: File, config: Config) => {
  const { url, bucket, key, media_upload_id, fields } = await getPresignedPutUrl(file, config);
  await uploadMedia(file, url, fields);
  return {
    src: `/media/?key=${key}`, // url to be inlined for subsequent reading
    url, // raw url to the asset in S3
    bucket, // name of the bucket with the asset in S3
    key, // key for the asset within the bucket in S3
    media_upload_id, // id for the MediaUpload object representing this upload
  };
};

export default uploadToS3;
