import React from "react";
import { Editor, Range } from "@tiptap/core";
import {
  faBold,
  faItalic,
  faLink,
  faHeading,
  faListOl,
  faListUl,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";
import { Level } from "@tiptap/extension-heading";
import { faCommentAlt, faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Extension from "../factories/Extension";
import { ToggleHeadingCommand } from "../TipTapEditor";

const char = "/";
export const name = "slash-commands";

type CommandProps = {
  editor: Editor;
  range: Range;
};

type SlashCommandType = {
  name: string;
  icon: IconDefinition;
  command: (arg: CommandProps) => void;
};

const Commands = ({
  containerRef,
  setShowGifModal,
  setShowLinkEditor,
  largeHeadingSize = 1,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  setShowGifModal: (arg: boolean) => void;
  setShowLinkEditor: (arg: boolean) => void;
  largeHeadingSize: Level;
}) => {
  const commands: SlashCommandType[] = [
    {
      name: "Heading Large",
      icon: faHeading,
      command: ({ editor, range }) => {
        (editor.chain().focus() as ToggleHeadingCommand)
          .deleteRange(range)
          .toggleHeading({ level: largeHeadingSize })
          .run();
      },
    },
    {
      name: "Heading Small",
      icon: faHeading,
      command: ({ editor, range }) => {
        (editor.chain().focus() as ToggleHeadingCommand)
          .deleteRange(range)
          .toggleHeading({ level: 3 })
          .run();
      },
    },
    {
      name: "Bold",
      icon: faBold,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleMark("bold").run();
      },
    },
    {
      name: "Italic",
      icon: faItalic,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleMark("italic").run();
      },
    },
    {
      name: "Link",
      icon: faLink,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run();
        setShowLinkEditor(true);
      },
    },
    {
      name: "Ordered List",
      icon: faListOl,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
    {
      name: "Unordered List",
      icon: faListUl,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    {
      name: "Blockquote",
      icon: faCommentAlt,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBlockquote().run();
      },
    },
    {
      name: "Code Block",
      icon: faTerminal,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
      },
    },
    {
      name: "Gif",
      icon: faImage,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run();
        setShowGifModal(true);
      },
    },
  ];

  return Extension<SlashCommandType>({
    name,
    char,
    containerRef,
    suggestionsClassname: "slash-commands-suggestions",

    async onQuery(query: string): Promise<SlashCommandType[]> {
      return commands.filter((i) => i.name.toLowerCase().includes(query.toLowerCase())).slice(0, 5);
    },

    Hit: ({ item }: { item: SlashCommandType }) => (
      <div className="command-suggestion row align-center">
        <FontAwesomeIcon icon={item.icon} />
        <div>{item.name}</div>
      </div>
    ),

    suggestionCommand: ({
      editor,
      range,
      props,
    }: CommandProps & {
      props: SlashCommandType;
    }) => {
      const { command } = props;
      command({ editor, range });
    },
  });
};

export default Commands;
