import React from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import ReactDOM from "react-dom";
import autosize from "autosize";
import getAlgoliaClient from "../algoliaClient";
import "./editor.scss";

const client = getAlgoliaClient();
let companyIndex;
let userIndex;

export const hasMention = (s) => /\[@.+\]\(.+\)/.test(s);

export const getMentionCandidates = async (query, hitsPerPage = 3) => {
  companyIndex = companyIndex || client.initIndex(`Company_${window.RAILS_ENV}`);
  userIndex = userIndex || client.initIndex(`Users_${window.RAILS_ENV}`);

  const [companies, users] = await Promise.all([
    companyIndex.search(query, {
      hitsPerPage,
      tagFilters: "yc_co",
    }),
    userIndex.search(query, { hitsPerPage }),
  ]);
  return [...companies.hits, ...users.hits].slice(0, 6).map((d) => ({
    name: d.name || `${d.first_name} ${d.last_name}`,
    href: d.search_path,
    avatar: d.small_logo_thumb_url || d.avatar_thumb,
  }));
};

export const MentionWarningText = () => (
  <div className="mention-warning">
    @mentioning a user or company will automatically send them an email linking to this post. Please
    be considerate when deciding whether to mention someone.
  </div>
);

export const MentionHit = ({ avatar, name }) => (
  <div className="mention-suggestion row align-center">
    <div
      className="shared-editor-suggestion-avatar"
      style={{ backgroundImage: `url(${avatar})` }}
    />
    <div>{name || ""}</div>
  </div>
);

const WrappedMentionHit = ({ avatar, name }) => (
  <div className="shared-editor-suggestion">
    <MentionHit avatar={avatar} name={name} />
  </div>
);

export default class MentionTextarea extends React.Component {
  componentDidMount() {
    const textarea = this.textarea();
    autosize(textarea);
    if (this.props.focus) {
      textarea.focus();
    }
  }

  componentDidUpdate({ value }) {
    if (this.props.value !== value) {
      const node = this.textarea();
      node.selectionStart = this._caretPosition;
      node.selectionEnd = this._caretPosition;
    }
  }

  onChange = (e) => {
    // This is some hacky code that replaces a " " with a "_" when we're in the middle of a mention.
    // The mention library we use terminates the search query with whitespace, so there's no way to
    // search for eg. "Michael Seibel" directly. By replacing the space with an _ however, we
    // search for "Michael_Seibel" instead which Algolia and react-textarea-autocomplete can both handle.
    const cursor = e.target.selectionStart;

    let beforeCursor = e.target.value.substring(0, cursor);
    const afterCursor = e.target.value.substring(cursor);

    if (/@[0-9a-zA-Z-]+ $/.test(beforeCursor)) {
      beforeCursor = beforeCursor.replace(/ $/, "_");
    }
    this.props.onChange(beforeCursor + afterCursor);
    this._caretPosition = Number(e.target.selectionEnd);
  };

  textarea = () => ReactDOM.findDOMNode(this).querySelector("textarea"); // eslint-disable-line react/no-find-dom-node

  render() {
    const { mentionsEnabled, value, onChange, focus, ...props } = this.props;
    return (
      <div className="mention-textarea-container shared-editor-suggestions">
        <ReactTextareaAutocomplete
          /* eslint-disable-next-line */
          {...props}
          value={value}
          onChange={this.onChange}
          className="form-control"
          loadingComponent={() => null}
          closeOnClickOutside
          movePopupAsYouType
          trigger={
            mentionsEnabled
              ? {
                  "@": {
                    dataProvider: getMentionCandidates,
                    component: ({ entity }) => WrappedMentionHit({ ...entity }),
                    output: ({ name, href }) => `[@${name}](${href})`,
                  },
                }
              : {}
          }
        />
        {mentionsEnabled && hasMention(value) && <MentionWarningText />}
      </div>
    );
  }
}
