import { Image as TipTapImage } from "@tiptap/extension-image";
import uploadToS3 from "../uploads/upload";
import loader from "../../../loaders/svg/spinner-loader.svg";

const Image = TipTapImage.extend({
  inline() {
    return false;
  },
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      file: {
        default: null,
      },
      onload: {
        default: null,
      },
    };
  },
  addNodeView() {
    return ({ editor, node, getPos, HTMLAttributes }) => {
      const { file, src } = HTMLAttributes;
      // create dom display
      const p = document.createElement("p");
      const img = document.createElement("img");
      img.setAttribute("src", file ? loader : src);
      img.setAttribute("class", file ? "image-loading" : "");
      p.appendChild(img);
      // determine if we need to upload the file
      if (file) {
        uploadToS3(file, { upload_type: "forum" }).then((result) => {
          // update the user-facing dom with the new image
          img.onload = () => {
            // update the node attributes for conversion to prosemirror
            editor.view.dispatch(
              // @ts-ignore TS2349 - upstream type declarations on getPos() need updating
              editor.view.state.tr.setNodeMarkup(getPos(), undefined, {
                src: result.src,
                alt: "uploaded image",
              })
            );
            // return focus to the editor
            editor.commands.focus();
          };
          img.setAttribute("src", result.src);
        });
      }
      // return dom
      return {
        dom: p,
      };
    };
  },
});

export default Image;
