import algoliasearch from "algoliasearch";

// Since we configure algolia directly from the window object, make it a lazy access function
// to avoid referencing window.AlgoliaOpts before it is populated or if, e.g. this code were
// eventually used in a SSR context. Of course, for SSR, it'll need to be modified to not attempt
// to read from window at all...
function memoizedGetClient() {
  let result = {};
  let called = false;

  function memoized() {
    if (called) {
      return result;
    }
    called = true;

    if (typeof window !== "undefined") {
      try {
        if (window.AlgoliaOpts.indices_not_set) {
          console.debug("Algolia indices not set"); // eslint-disable-line no-console
        } else {
          result = algoliasearch(window.AlgoliaOpts.app, window.AlgoliaOpts.key);
        }
      } catch (e) {
        console.error(e);
      }
    }
    return result;
  }

  return memoized;
}

/** @type {() => import("algoliasearch").Client} */
export default memoizedGetClient();
