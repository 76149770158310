import React from "react";
import { mergeAttributes } from "@tiptap/core";
import { Node as ProseMirrorNode } from "prosemirror-model";
import { init, SearchIndex } from "emoji-mart";
import Extension, { RenderHTMLPropTypes } from "../factories/Extension";

init({});
const char = ":";
const dataKey = "data-type";
export const name = "emoji";
const renderText = ({ node }: { node: ProseMirrorNode }) => node.attrs.native;

export type EmojiType = {
  name: string;
  native: string;
};

type EmojiResponseType = {
  skins: { native: string; shortcodes: string }[];
};

const Emoji = ({ containerRef }: { containerRef: React.RefObject<HTMLDivElement> }) =>
  Extension<EmojiType>({
    name,
    char,
    containerRef,
    renderText,

    async onQuery(query: string): Promise<EmojiType[]> {
      const defaults = [
        { name: "grinning", native: "😀" },
        { name: "smile", native: "😄" },
        { name: "smiley", native: "😃" },
        { name: "laughing", native: "😆" },
        { name: "+1", native: "👍" },
      ];
      let q = query;
      if (q?.length === 0) {
        return Promise.resolve(defaults);
      }
      // if the user just types :), the query is just ")". Handle smiley and unsmiley
      // faces as special cases
      if (q === ")" || q === "(" || q === "\\" || q === "/") {
        q = `:${q}`;
      }

      const results = await SearchIndex.search(q || "");
      if (results && results.length) {
        return Promise.resolve(
          (results as EmojiResponseType[]).slice(0, 5).map((i) => ({
            name: i.skins[0].shortcodes,
            native: i.skins[0].native,
          }))
        );
      }
      return Promise.resolve([]);
    },

    addAttributes: () => ({
      name: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element: HTMLElement) => element.getAttribute("data-name"),
        renderHTML: (item: EmojiType) => ({
          "data-name": item.name,
        }),
      },
      native: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element: HTMLElement) => element.getAttribute("data-native"),
        renderHTML: (item: EmojiType) => ({
          "data-native": item.native,
        }),
      },
    }),

    parseHTML: () => [{ tag: `[${dataKey}="${name}"]` }],

    renderHTML: ({ node, HTMLAttributes }: RenderHTMLPropTypes) => [
      "span",
      mergeAttributes({ [dataKey]: name }, HTMLAttributes),
      renderText({ node }),
    ],

    Hit: ({ item }: { item: EmojiType }) => (
      <div className="emoji-suggestion row align-center">
        <div>{item.native}</div>
        <div>{item.name}</div>
      </div>
    ),
  });

export default Emoji;
