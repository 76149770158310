import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Node, Editor, Range, Attributes } from "@tiptap/core";
import { Node as ProseMirrorNode, DOMOutputSpec } from "prosemirror-model";
import { PluginKey as PluginKeyType } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
// @ts-ignore TS2307 - types are missing upstream
import Suggestion from "@tiptap/suggestion";
// import Suggestion from "./Suggestion"
import { ReactRenderer } from "@tiptap/react";
import tippy, { Instance as TippyInstance } from "tippy.js";
import { getSelection } from "../TipTapEditor";

export type RenderHTMLPropTypes = {
  node: ProseMirrorNode;
  HTMLAttributes: Attributes;
};

function Extension<SuggestionItem extends { name: string }>({
  name,
  char,
  containerRef,
  onQuery,
  Hit,
  addAttributes,
  renderText,
  renderHTML,
  parseHTML,
  suggestionCommand,
  suggestionsClassname,
}: {
  name: string;
  char: string;
  containerRef: React.RefObject<HTMLDivElement>;
  onQuery: (query: string) => Promise<SuggestionItem[]>;
  Hit: ({ item }: { item: SuggestionItem }) => JSX.Element;
  addAttributes?: () => Attributes;
  renderText?: ({ node }: { node: ProseMirrorNode }) => string;
  renderHTML?: ({
    node,
    HTMLAttributes,
  }: {
    node: ProseMirrorNode;
    HTMLAttributes: Attributes;
  }) => DOMOutputSpec;
  parseHTML?: () => string | { tag: string }[];
  suggestionCommand?: (props: { editor: Editor; range: Range; props: SuggestionItem }) => void;
  suggestionsClassname?: string;
}) {
  const PluginKey = new PluginKeyType(name);

  const PluginNode = Node.create({
    name,
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    addAttributes,
    parseHtml: parseHTML,
    renderHTML,
    renderText,

    addOptions() {
      return {
        HTMLAttributes: {
          class: name,
        },
        renderLabel: renderText,
        suggestion: {
          char,
          pluginKey: PluginKey,
          allowSpaces: false,

          items: async ({ query }: { query: string }): Promise<SuggestionItem[]> =>
            onQuery(query.replaceAll("_", " ")),

          render: () => {
            let reactRenderer: ReactRenderer;
            let tooltip: TippyInstance;
            let tooltipVisible = false;
            let editor: Editor;

            interface SuggestionRenderProps {
              editor: Editor;
              command: (props: { editor: Editor; range: Range; props: SuggestionItem }) => void;
              range: Range;
              query: string;
              text: string;
              items: SuggestionItem[];
              decorationNode: Element | null;
              clientRect: (() => DOMRect) | null;
            }

            interface SuggestionKeyDownProps {
              view: EditorView;
              event: KeyboardEvent;
              range: Range;
            }

            const extendProps = (props: SuggestionRenderProps | SuggestionKeyDownProps) => ({
              ...props,
              Hit,
              tooltipVisible,
            });

            return {
              onStart: (props: SuggestionRenderProps) => {
                reactRenderer = new ReactRenderer(Suggestions, {
                  editor: props.editor,
                  props: extendProps(props),
                });

                editor = editor || props.editor;

                if (containerRef?.current) {
                  // @ts-ignore partial props declaration
                  tooltip = tippy(containerRef.current, {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => containerRef.current,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    hideOnClick: true,
                    onHide: () => {
                      tooltipVisible = false;
                      reactRenderer.updateProps(extendProps(props));
                    },
                    onShow: () => {
                      tooltipVisible = true;
                      reactRenderer.updateProps(extendProps(props));
                    },
                  });
                }
              },

              onUpdate(props: SuggestionRenderProps) {
                tooltip.setProps({
                  getReferenceClientRect: props.clientRect,
                });
                reactRenderer.updateProps(extendProps(props));
              },

              onKeyDown(props: SuggestionKeyDownProps) {
                // if the user sends a space key, convert to _
                if (props.event.key === " ") {
                  const { $cursor, state } = getSelection(editor);
                  if ($cursor) {
                    const { pos } = $cursor;
                    const last = state.doc.textBetween(pos - 1, pos);
                    if (last !== "_") {
                      props.event.stopPropagation();
                      props.event.preventDefault();
                      editor
                        .chain()
                        .setTextSelection({
                          from: pos,
                          to: pos + 1,
                        })
                        .insertContent("_")
                        .run();
                    }
                  }
                }
                // handle tooltip updates
                if (props.event.key === "Escape") {
                  tooltip.hide();
                  props.event.stopPropagation();
                  return true;
                }
                // @ts-ignore TS2571
                return reactRenderer?.ref?.onKeyDown(extendProps(props));
              },

              onExit() {
                if (tooltip?.state && !tooltip.state?.isDestroyed) tooltip.destroy();
                reactRenderer.destroy();
              },
            };
          },

          command: ({
            editor,
            range,
            props,
          }: {
            editor: Editor;
            range: Range;
            props: SuggestionItem;
          }) => {
            if (suggestionCommand) {
              return suggestionCommand({
                editor,
                range,
                props,
              });
            }
            // if the next node is text and starts with ' ', increase the range.to length by one
            const { nodeAfter } = editor.view.state.selection.$to;
            const overrideSpace = nodeAfter?.text?.startsWith(" ");
            if (overrideSpace) range.to += 1;
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: this.name,
                  attrs: props,
                },
                {
                  type: "text",
                  text: " ",
                },
              ])
              .run();
          },

          allow: ({ editor, range }: { editor: Editor; range: Range }) => {
            const $from = editor.state.doc.resolve(range.from);
            const type = editor.schema.nodes[this.name];
            const allow = !!$from.parent.type.contentMatch.matchType(type);
            return allow;
          },
        },
      };
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isMention = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node: ProseMirrorNode, pos) => {
              if (node.type.name === this.name) {
                isMention = true;
                tr.insertText(this.options.suggestion.char || "", pos, pos + node.nodeSize);
                return false;
              }
            });

            return isMention;
          }),
      };
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
  });

  // Suggestions UI
  const Suggestions = forwardRef(
    (
      {
        items,
        tooltipVisible,
        command,
      }: {
        items: SuggestionItem[];
        tooltipVisible: boolean;
        command: (item: SuggestionItem) => void;
      },
      ref
    ) => {
      const [highlightedIndex, setHighlightedIndex] = useState(0);

      const selectItem = (index: number) => {
        const item = items[index];
        if (item) {
          command({ ...item });
        }
      };

      useEffect(() => {
        setHighlightedIndex(0);
      }, [items]);

      useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === "ArrowUp") {
            setHighlightedIndex((highlightedIndex + items.length - 1) % items.length);
            return true;
          }

          if (event.key === "ArrowDown") {
            setHighlightedIndex((highlightedIndex + 1) % items.length);
            return true;
          }

          if (event.key === "Enter") {
            selectItem(highlightedIndex);
            return true;
          }

          return false;
        },
      }));

      return (
        <div className={`shared-editor-suggestions ${suggestionsClassname}`}>
          {items.length
            ? items.map((item: SuggestionItem, index: number) => (
                <button
                  className={`shared-editor-suggestion ${
                    index === highlightedIndex ? "highlighted" : ""
                  }`}
                  key={item.name}
                  onClick={() => selectItem(index)}
                  onMouseMove={() => setHighlightedIndex(index)}
                >
                  <Hit item={item} />
                </button>
              ))
            : null}
        </div>
      );
    }
  );

  return {
    PluginKey,
    Node: PluginNode,
  };
}

export default Extension;
